@media screen and (max-width: 374px) {
    footer {
        & .container {
            & ul {
                display: none;
            }
            & h2 {
                margin-top: 30px;
            }
        }
    }
}