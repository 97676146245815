@media screen and (max-width: 1023px) {
    .about-us {
        & .container {
            background-position: -70% center;
        }
    }
}
@media screen and (max-width: 767px) {
    .about-us {
        & .container {
            background-size: 320px;
            background-position: center top;
            min-height: 320px;
            & .text {
                position: relative;
                top: auto;
                left: auto;
                transform: none;
                margin-top: 320px;
            }
        }
    }
}