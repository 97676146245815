@media screen and (max-width: 767px) {
    .contactPage {
        & .container {
            & ul {
                flex-direction: column;
                & li {
                    max-width: 100%;
                    & ul {
                        flex-direction: column !important;
                        & li {
                            max-width: 100%;
                            margin-right: 0 !important;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
            & .button {
                max-width: 100%;
                width: 100%;
            }
            & .infos {
                flex-direction: column;
                & a {
                    text-align: center;
                    margin-bottom: 50px;
                    padding-top: 40px;
                    position: relative;
                    padding-left: 0;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &::before {
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }
}