.slider {
    position: relative;
    width: 100%;
    height: 100vh;
    &::before {
        content: "";
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--slider-background) no-repeat bottom center / 1920px;
        z-index: 2;
    }
    & .slide {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        background: url('/assets/image/slider/slider.webp') no-repeat center center / cover;
        &::before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(25, 59, 84, .5);
        }
        & .caption {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 75%;
            z-index: 3;
            & div {
                text-align: center;
                display: block;
                color: #FFF;
                &:nth-child(1) {
                    font-size: 48px;
                    font-weight: 900;
                }
                &:nth-child(2) {
                    font-size: 20px;
                    max-width: 550px;
                    margin: 0 auto 80px;
                }
                &.button {
                    max-width: 255px;
                    height: 60px;
                    line-height: 60px;
                    cursor: pointer;
                    margin: 0 auto;
                    font-size: 25px;
                    font-weight: 700;
                    border: 1px solid #FFF;
                    transition: all .3s ease-in-out;
                    &:hover {
                        color: #333;
                        background-color: #FFF;
                    }
                }
            }
        }
    }
}

header {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid rgba(255,255,255, .2);
    z-index: 4;
    & .container {
        & .contact {
            display: flex;
            justify-content: space-between;
            max-width: 60%;
            width: 100%;
            line-height: 30px;
            & .email {
                padding-left: 30px;
                font-size: 14px;
                background: url(/assets/image/icons/mail-white.svg) no-repeat left center / 18px;
                color: #fff;
                height: 30px;
                margin: auto 0;
                &:hover {
                    color: var(--default-color);
                }
            }
            & .phone {
                height: 30px;
                margin: auto 0 auto auto;
                display: flex;
                & a {
                    padding-left: 20px;
                    font-size: 14px;
                    background: url(/assets/image/icons/phone-white.svg) no-repeat left center / 12px;
                    color: #fff;
                    &:nth-child(1) {
                        padding-right: 30px;
                        border-right: 1px solid rgba(255,255,255, .2);
                        margin-right: 30px
                    }
                    &:hover {
                        color: var(--default-color);
                    }
                }
            }
        }
        & .options {
            margin-left: auto;
            display: flex;
            & a {
                width: 12px;
                height: 12px;
                text-indent: -1000000px;
                background: url(/assets/image/icons/in.svg) no-repeat center center;
                display: block;
                margin: auto 0;
            }
            & .change-mode {
                width: var(--change-mode-width);
                height: 12px;
                margin: auto 20px;
                cursor: pointer;
                background: var(--change-mode-background) no-repeat center center;
            }
            & .language {
                width: 60px;
                margin-top: 19px;
                display: flex;
                flex-direction: column;
                position: relative;
                z-index: 1;
                &::before {
                    content: "";
                    width: 70px;
                    background: #ffffff;
                    position: absolute;
                    left: -5px;
                    top: -5px;
                    border-radius: 5px;
                    transition: all .2s;
                    max-height: 0;
                    height: 100%;
                }
                & li {
                    padding-left: 25px;
                    color: #FFF;
                    cursor: pointer;
                    display: none;
                    order: 2;
                    position: relative;
                    z-index: 2;
                    margin-bottom: 10px;
                    font-size: 14px;
                    &.active {
                        display: block;
                        order: 1;
                        position: relative;
                        &::after {
                            content: "";
                            width: 8px;
                            height: 3px;
                            background: url(/assets/image/icons/arrow-down.svg) no-repeat center center;
                            position: absolute;
                            right: 0px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                    &.english {
                        background: url(/assets/image/icons/en.svg) no-repeat center left;
                    }
                    &.spanish {
                        background: url(/assets/image/icons/es.svg) no-repeat center left;
                    }
                    &.portuguese {
                        background: url(/assets/image/icons/pt.svg) no-repeat center left;
                    }
                }
                &.open {
                    &::before {
                        max-height: 90px;
                        height: 90px;
                    }
                    & li {
                        display: block;
                        color: #333;
                        &.active {
                            &::after {
                                background: url(/assets/image/icons/arrow-up.svg) no-repeat center center;
                            }
                        }
                    }
                }
            }
        }
    }
}

.menu {
    position: absolute;
    top: 60px;
    left: 0;
    display: block;
    z-index: 3;
    width: 100%;
    height: 100px;
    & .container {
        & a {
            display: block;
            width: 80px;
            height: 44px;
            background: var(--logo) no-repeat center center / cover;
            text-indent: -1000000px;
            margin: auto 0;
        }
        & ul {
            margin: auto 0 auto auto;
            display: flex;
            justify-content: space-between;
            & li {
                cursor: pointer;
                margin-right: 30px;
                color: #fff;
                font-size: 16px;
                font-weight: 700;
                position: relative;
                padding-bottom: 15px;
                &::after {
                    content: "";
                    max-width: 0px;
                    height: 5px;
                    width: 100%;
                    background-color: var(--default-color);
                    transition: all .5s;
                    border-radius: 5px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
                &:hover {
                    &::after {
                        max-width: 30px;
                    }
                }
                &.active {
                    &::after {
                        max-width: 30px;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        & .icon-menu {
            display: none;
            width: 30px;
            height: 23px;
            justify-content: space-between;
            flex-direction: column;
            align-self: center;
            margin-left: auto;
            & span {
                width: 100%;
                height: 5px;
                background: #FFFFFF;
                border-radius:5px;
                margin-bottom: 4px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    &.fixed {
        background-color: var(--background-color);
        position: fixed;
        top: 0;
        box-shadow: 0 0 20px rgba(0,0,0,.5);
        & .container {
            & a {
                background: var(--logo-color) no-repeat center center / cover;
            }
            & ul {
                & li {
                    color: var(--text-color);
                }
            }
        }
    }
}

.menu-mobile {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    overflow: hidden;
    transition: all .5s ease-in-out;
    z-index: 10;
    background-color: var(--background-color);
    transform: translate(-50%, -50%);
    &.active {
        width: 100%;
        height: 100%;
        display: table;
        & ul {
            opacity: 1;
        }
    }
    & span {
        position: absolute;
        right: 20px;
        top: 20px;
        text-indent: -100000px;
        width: 30px;
        height: 30px;
        &::before {
            content: "";
            width: 30px;
            height: 2px;
            background-color: var(--text-color);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &::after {
            content: "";
            width: 30px;
            height: 2px;
            background-color: var(--text-color);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
    & ul {
		display: table-cell;
		vertical-align: middle;
        transition: all .5s .5s ease-in-out;
        opacity: 0;
        margin-top: 50px;
        & li {
            display: block;
            padding: 30px 20px;
            cursor: pointer;
            font-size: 20px;
            text-align: center;
            position: relative;
            &.active {
                color: var(--default-color);
            }
        }
    }
}