.services {
    display: block;
    padding: 50px 0;
    background: rgba(138, 43, 169, .05) url(/assets/image/services/dashed-line.svg) no-repeat center center / contain;
    & .container {
        display: block;
        & ul {
            display: flex;
            margin-top: 50px;
            justify-content: space-between;
            & li {
                counter-increment: inst;
                position: relative;
                padding-top: 30px;
                max-width: 232px;
                width: 100%;
                display: flex;
                flex-direction: column;
                &::before {
                    content:  "0" counter(inst);
                    position: absolute;
                    top: -30px;
                    left: 50%;
                    transform: translateX(-50%);
                    color: var(--text-color);
                    opacity: .1;
                    font-size: 100px;
                    font-weight: 900;
                    z-index: 1;
                }
                & .icon {
                    width: 84px;
                    height: 84px;
                    background-color: #FFFFFF;
                    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
                    margin: 0 auto 50px;
                    position: relative;
                    z-index: 2;
                    border-radius: 50%;
                    background-repeat: no-repeat;
                    background-position: center center;
                    &::after {
                        content: "";
                        position: absolute;
                        bottom: -30px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 30px;
                        height: 3px;
                        background: var(--default-color);
                        border-radius: 3px;
                    }
                }
                &:nth-child(1) {
                    & .icon {
                        background-image: url(/assets/image/icons/computer.svg);
                    }
                }
                &:nth-child(2) {
                    & .icon {
                        background-image: url(/assets/image/icons/smartphone.svg);
                    }
                }
                &:nth-child(3) {
                    & .icon {
                        background-image: url(/assets/image/icons/api.svg);
                    }
                }
                & .title {
                    font-size: 22px;
                    font-weight: 700;
                    color: var(--text-color);
                    text-align: center;
                }
                & .description {
                    font-size: 16px;
                    color: var(--text-color);
                    margin-top: 30px;
                    text-align: center;
                }
            }
        }
    }
}
.message {
    display: block;
    padding: 28px 10px;
    font-size: 20px;
    font-weight: 700;
    color: var(--default-color);
    background: rgba(138, 43, 169, .15);
    text-align: center;
    & span {
        color: var(--text-color);
        text-decoration: underline;
        cursor: pointer;
    }
}