.about-us {
    background-color: var(--background-color);
    width: 100%;
    padding-bottom: 50px;
    & .container {
        background: url(/assets/image/about-us/image.png) no-repeat left center / contain;
        min-height: 650px;
        position: relative;
        & .text {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateY(-50%);
            & h2 {
                font-size: 16px;
                color: var(--text-color);
                font-weight: 400;
            }
        }
    }
}