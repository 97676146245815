@media screen and (max-width: 1023px) {
    .slider {
        margin-bottom: 50px;
        &::before {
            display: none;
        }
    }
    header {
        & .container {
            & .options {
                display: none;
            }
            & .contact {
                max-width: 100%;
            }
        }
    }
    .menu {
        & .container {
            & ul {
                display: none;
            }
            & .icon-menu {
                display: flex;
            }
        }
        &.fixed {
            & .container {
                & .icon-menu {
                    & span {
                        background-color: var(--text-color);
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    header {
        display: none;
    }
    .menu {
        top: 0;
    }
}