.contactPage {
    display: block;
    width: 100%;
    padding: 50px 0;
    background: url(/assets/image/contact/world-map.svg) no-repeat center center / cover;
    & .container {
        flex-direction: column;
        & .form {
            display: flex;
            margin-top: 30px;
            flex-wrap: wrap;
            & .field {
                margin-bottom: 20px;
                margin-right: 20px;
                position: relative;
                & label {
                    font-size: 18px;
                }
                & ul {
                    display: flex;
                    flex-direction: row;
                    margin-top: 10px;
                    & li {
                        padding: 10px 30px 10px 10px;
                        border: 1px solid var(--text-color);
                        border-radius: 5px;
                        font-size: 16px;
                        font-weight: 700;
                        position: relative;
                        margin-right: 20px;
                        cursor: pointer;
                        transition: all .3s ease-in-out;
                        background: transparent;
                        min-width: auto;
                        flex: unset;
                        &::after {
                            content: "+";
                            position: absolute;
                            top: 50%;
                            right: 10px;
                            transform: translateY(-50%);
                            font-size: 16px;
                            font-weight: 700;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                        &:hover, &.active {
                            background: var(--default-color);
                            border-color: var(--default-color);
                            color: #FFFFFF;
                        }
                    }
                }
                &:nth-child(1), &:nth-child(5) {
                    min-width: 100%;
                }
                &:nth-child(2) {
                    flex: 1;
                }
                &:nth-child(3) {
                    flex: 1;
                }
                &:nth-child(4) {
                    flex: 2;
                }
                &.float-label {
                    padding-top: 10px;
                    & label {
                        position:absolute;
                        pointer-events:none;
                        left:0px;
                        top:50%;
                        transform: translateY(-50%);
                        transition:0.2s ease all; 
                    }
                }
                &.nm {
                    margin-right: 0;
                }
                & input {
                    background: transparent;
                    border:none;
                    border-bottom: 1px solid var(--text-color);
                    height: 40px;
                    font-size: 16px;
                    transition:0.2s ease all; 
                    font-weight: 700;
                    width: 100%;
                    &:focus, &:not(:placeholder-shown){
                        outline:none;
                        border-bottom-color: var(--default-color);
                        ~ label{
                            top:-5px;
                        }
                    }
                }
                & textarea {
                    background: transparent;
                    border:none;
                    border-bottom: 1px solid var(--text-color);
                    font-size: 16px;
                    transition:0.2s ease all; 
                    font-weight: 700;
                    width: 100%;
                    margin-top: 10px;
                    resize: none;
                    &:focus, &:not(:placeholder-shown){
                        outline:none;
                        border-bottom-color: var(--default-color);
                    }
                }
            }
        }
        & .button {
            margin-top: 50px;
            display: block;
            padding: 0 30px 0 60px;
            height: 50px;
            line-height: 48px;
            background: var(--airplane) no-repeat 20px center;
            font-size: 18px;
            font-weight: 700;
            color: var(--text-color);
            border: 1px solid var(--text-color);
            text-align: center;
            cursor: pointer;
            transition: all .3s ease-in-out;
            &:hover {
                background: var(--default-color) url(/assets/image/icons/airplane-white.svg) no-repeat 20px center;
                border-color: var(--default-color);
                color: #FFFFFF;
            }
        }
        & .infos {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 50px;
            & a {
                font-size: 22px;
                color: var(--default-color);
                padding-left: 60px;
                position: relative;
                height: 50px;
                line-height: 50px;
                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                }
                &:nth-child(1)::before {
                    background: var(--default-color) url(/assets/image/icons/mail-white.svg) no-repeat center center;
                }
                &:nth-child(2)::before, &:nth-child(3)::before {
                    background: var(--default-color) url(/assets/image/icons/phone-white.svg) no-repeat center center;
                }
            }
        }
    }
}