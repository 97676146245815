@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800;900&display=swap');

:root {
    --default-color: #BD2DCA;
    --background-color: #FFFFFF;
    --text-color: #333333;
    --slider-background: url('/assets/image/slider/background.svg');
	--change-mode-background: url('/assets/image/icons/lamp-white.svg');
	--change-mode-width: 9px;
    --loader-logo: url('/assets/image/logo-color.svg');
    --airplane: url('/assets/image/icons/airplane.svg');
    --logo: url(/assets/image/logo-white.svg);
    --logo-color: url(/assets/image/logo-color.svg);
}
:root .dark {
    --background-color: #333333;
    --text-color: #FFFFFF;
    --slider-background: url('/assets/image/slider/background-dark.svg');
	--change-mode-background: url('/assets/image/icons/lamp-dark.svg');
	--change-mode-width: 14px;
    --loader-logo: url('/assets/image/logo-white.svg');
    --airplane: url('/assets/image/icons/airplane-white.svg');
    --logo: url(/assets/image/logo-white.svg);
    --logo-color: url(/assets/image/logo-white.svg);
}

* {
    font-family: 'Nunito Sans', sans-serif;
    box-sizing: border-box;
    color: var(--text-color);
}

body, html {
    margin: 0;
    padding: 0;
    border: 0;
    background-color: var(--background-color);
    min-width: 100%;
    min-height: 100vh;
    scroll-behavior: smooth;
}

ul, li {
    list-style: none;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

p {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    transition: all .3s ease-in-out;
    &:hover {
        color: var(--default-color);
    }
}

.container {
	display: flex;
	max-width: 1200px;
	width: 100%;
	margin:0 auto;
	padding: 0 10px;
	box-sizing: border-box;
	height: 100%;
	&.ph {
		padding:50px 0;
	}
}

.title {
    display: block;
    & .small {
        display: block;
        font-size: 22px;
        color: var(--default-color);
        font-weight: 700;
        margin-bottom: 15px;
    }
    & .large {
        font-size: 36px;
        color: var(--text-color);
        font-weight: 400;
        display: block;
        padding-left: 20px;
        position: relative;
        &::before {
            content: "";
            width: 3px;
            height: 20px;
            background-color: var(--default-color);
            border-radius:3px;
            position: absolute;
            top: 10px;
            left: 0;
        }
    }
}