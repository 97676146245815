footer {
    display: block;
    width: 100%;
    padding-top: 50px;
    background: #200063;
    position: relative;

    & .container {
        display: flex;
        flex-direction: column;

        & .logo {
            margin: 0 auto;
            width: 138px;
            height: 75px;
            background: url(/assets/image/logo-white.svg) no-repeat center center / cover;
        }

        & ul {
            display: flex;
            margin: 20px auto;
            justify-content: space-between;

            & li {
                font-size: 16px;
                font-weight: 700;
                color: #fff;
                cursor: pointer;
                margin-right: 30px;
                transition: all .5s;

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    color: var(--default-color);
                }
            }
        }

        & h2 {
            padding: 0;
            font-size: 16px;
            font-weight: 400;
            max-width: 790px;
            text-align: center;
            color: #FFFFFF;
            margin: 0 auto 50px;
        }

        & a {
            height: 50px;
            line-height: 50px;
            text-indent: -10000000px;
            text-align: center;
            background: url(/assets/image/icons/in.svg) no-repeat center center;
        }
    }

    &::before {
        content: "";
        width: calc(50% - 160px);
        height: 50px;
        background-color: var(--default-color);
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 0 50px 0 0;
    }

    &::after {
        content: "";
        width: calc(50% - 160px);
        height: 50px;
        background-color: var(--default-color);
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 50px 0 0 0;
    }
}