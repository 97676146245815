@media screen and (max-width: 767px) {
    .services {
        & .container {
            & ul {
                flex-direction: column;
                & li {
                    max-width: 100%;
                    margin-bottom: 30px;
                }
            }
        }
    }
}