.page-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--background-color);
    z-index: 10;
    animation: animate-loader-bg 2s ease-in-out infinite;

    &::after {
        content: "";
        position: absolute;
        top: calc(50% - 41px);
        left: calc(50% - 70px);
        transform: translate(-50%, -50%);
        width: 150px;
        height: 82px;
        background: var(--loader-logo) no-repeat center center / cover;
        animation: animate-loader 2s ease-in-out infinite;
    }
}

@keyframes animate-loader {
    0% {
        transform: rotate(0);
    }

    50% {
        transform: rotateY(180deg);
    }

    100% {
        transform: rotateY(360deg);
    }
}

@keyframes animate-loader-bg {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}